import "./event-card.scss";
import { format, formatISO } from "date-fns";
import { Row, Col } from "react-bootstrap";

const EventCard = ({
  date,
  city,
  state,
  title,
  address,
  startTime,
  endTime,
  description,
  organizer,
  organizerUrl,
  organizerImg,
  applicationUrl,
  eventImg,
  applicationOpen,
  eventUrl,
}) => {
  const startDay = format(new Date(`${startTime}`), "LLL");
  const startDate = format(new Date(`${startTime}`), "d");
  const start = format(new Date(`${startTime}`), "h:mm");
  const end = format(new Date(`${endTime}`), "h:mm a");

  return (
    <div className="event-card">
      <div>
        <div
          className="event-img-container"
          style={{ backgroundImage: `url(${eventImg})` }}
        ></div>
        <div
          className="date-container"
          style={{ position: "relative", top: "-11rem" }}
        >
          <p className="start-day">{startDay}</p>
          <p className="start-date">{startDate}</p>
        </div>
      </div>
      <div style={{ display: "flex", position: "relative", top: "-4rem" }}>
        <div>
          <div className="location-container">
            <div>
              {address} {city},{state}
            </div>
          </div>
          <h2 className="event-card-title">{title}</h2>

          <div className="info-container">
            {start} - {end}
          </div>
        </div>
      </div>
      <div style={{ position: "relative", top: "-4rem" }}>
        <Row>
          <Col>
            <a href={eventUrl} target="_blank">
              <button className="button">Event info</button>
            </a>{" "}
          </Col>
          <Col>
            {applicationOpen ? (
              <a href={applicationUrl} target="_blank">
                <button className="button">Apply</button>
              </a>
            ) : (
              <button className="disabled-button">Application N/A</button>
            )}
          </Col>
        </Row>

        <h5>
          Event by{" "}
          <a href={organizerUrl} target="_blank">
            {organizer}
          </a>
        </h5>
      </div>

      <div className="event-card-footer"></div>
    </div>
  );
};

export default EventCard;
