import * as React from "react";
import mainLogo from "../../images/logo.png";
import "./nav-bar.css";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

export default function NavBar() {
  return (
    // <React.Fragment>
    //   <Box
    //     className="nav-bar"
    //     sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
    //   >
    //     <img className="nav-logo" src={mainLogo}></img>
    //     <Typography sx={{ minWidth: 100 }}>
    //       <Link to="/">Home</Link>
    //     </Typography>
    //     <Typography sx={{ minWidth: 100 }}>
    //       <Link to="/about">About</Link>
    //     </Typography>
    //     <Typography sx={{ minWidth: 100 }}>
    //       <Link to="/organizer-directory">Organizer Directory</Link>
    //     </Typography>
    //     <Typography sx={{ minWidth: 100 }}>
    //       <Link to="/vendor-directory">Vendor Directory</Link>
    //     </Typography>
    //     {/* <Tooltip title="Account settings">
    //       <IconButton
    //         onClick={handleClick}
    //         size="small"
    //         sx={{ ml: 2 }}
    //         aria-controls={open ? "account-menu" : undefined}
    //         aria-haspopup="true"
    //         aria-expanded={open ? "true" : undefined}
    //       >
    //         <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
    //       </IconButton>
    //     </Tooltip> */}
    //   </Box>
    // </React.Fragment>
    // <nav className="nav-bar">
    //   <img className="nav-logo" src={mainLogo}></img>
    //   <ul>
    //     <li>
    //       <Link to="/">Home</Link>
    //     </li>

    //     <li>
    //       <a
    //         href="https://docs.google.com/forms/d/e/1FAIpQLSe9u1Yl-Cx3JyELsOXuH4s49rWll5qIOUpuQ17IWF_szWnt3w/viewform?usp=sf_link"
    //         target="_blank"
    //       >
    //         Submit a market
    //       </a>
    //     </li>
    //     {/* <li>
    //       <Link to="/vendor-directory">Vendor Directory</Link>
    //     </li>
    //     <li>
    //       <Link to="/organizer-directory">Organizer Directory</Link>
    //     </li> */}
    //   </ul>
    // </nav>

    <Navbar expand="lg" className=" bg-body-tertiary pnw-bg">
      <Navbar.Brand href="#home">
        <img className="nav-logo" src={mainLogo}></img>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href="/">Home</Nav.Link>
          {/* <Nav.Link href="/vendor-directory">Vendor Directory</Nav.Link> */}
          <Nav.Link
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSe9u1Yl-Cx3JyELsOXuH4s49rWll5qIOUpuQ17IWF_szWnt3w/viewform"
          >
            Submit market
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
