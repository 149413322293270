const vendorList = [
  {
    logo: "/images/bz-logo.jpg",
    name: "Black Zodiac Prints",
    owner: "Ryan Downs",
    categories: ["Illustration"],
    bio: "Hand drawn portraits of inspirational Black authors, artists, heroes and leaders.",
    instagram: "@blackzodiacprints",
    instagramUrl: "https://www.instagram.com/blackzodiacprints/",
  },
  {
    logo: "/images/golden-rose-logo.png",

    name: "Golden Rose Design Co.",
    owner: "Britt Vasina",
    categories: ["Jewelry"],

    bio: "Permanent jewelry located in Portland, Oregon specializing in custom permanent jewelry at private and corporate events and pop-ups.",
    instagram: "@blackzodiacprints",
  },
  {
    name: "Sapling Maker Lab",
    owner: "Jon",
    bio: "Leather, wood, and metal creations to use and last.",
    instagram: "@pdxward",
    categories: ["Jewlery"],
  },
  {
    name: "Cute Pickle Crafts",
    owner: "Mandi Vernoy",
    bio: "Handmade toys and dolls that are as unique as they are adorable. Let us sprinkle joy into your life with our delightful creations.",
    instagram: "@cutepicklecrafts",
    categories: ["Toys"],
  },
  {
    name: "She and the Moon",
    owner: "Victoria Moon",
    bio: "She and the Moon curates and creates hand made magic. From small batch beeswax candles, natural history displays, hand crafted and curated altar and witchcraft supplies, they have something for the witch in everyone.",
    instagram: "@she.and.the.moon",
    categories: ["Beauty and Skincare", "Candles"],
  },
  {
    name: "Pam Wishbow Illustration",
    owner: "Pam Wishbow",
    bio: "handmade illustrative prints, charms, and fortunes",
    instagram: "@pamwishbow",
  },
  {
    name: "Yangmal Jib",
    owner: "Soojin Kim",
    bio: "Yangmal Jib is a sock company based in Portland, Oregon that provides quality socks made from Korea. We aim to spread joy and excitement through expressive colors and unique designs.",
    instagram: "@yangmaljib",
  },
  {
    name: "Cathy Ward Jewelry",
    owner: "Cathy Ward",
    bio: "Handcrafted jewelry that is simple, modern, and just a little bit edgy. Where gorgeous jewelry design meets unique individuality. Sustainably handmade. ",
    instagram: "@cathywardjewelry",
  },
  {
    name: "Irie Illustrates",
    owner: "Irie Mock",
    bio: "I’m Irie the creator of Irie Illustrates, I am an independent black artist from the PNW. Each of my pieces are hand drawn original colored pencil illustrations. I don’t make any prints of my work so each piece is 1 of 1 and unique. The inspiration for the majority of my work comes predominantly from the world of Nikes as well as notable rappers.",
    instagram: "@Irie_illustrates",
  },
  {
    name: "Chula Charmer",
    owner: "Yvonne Rangel",
    bio: "Chula is a Spanish term of endearment that translates to 'cutie' or 'beautiful'.Charmers were practitioners of folk magic that specialized in supernatural healing. As someone drawn to helping others, I found my calling by combining my passions for the occult, crystals, style, and spirituality. My creations, designed with thoughtful intention, aim to serve those who resonate with their purpose.",
    instagram: "@chulacharmer",
  },
];

export default vendorList;
