import VendorCard from "./VendorCard";
import vendorList from "./vendorList";
import "./vendor-directory.scss";
import { Container, Row, Col } from "react-bootstrap";
const VendorDirectory = () => {
  console.log(vendorList);
  return (
    <Container className="container">
      <h1>Vendor Directory</h1>
      <Row>
        {vendorList.map((vendor) => {
          return (
            <Col lg={4}>
              <VendorCard
                logo={vendor.logo}
                name={vendor.name}
                bio={vendor.bio}
                instagram={vendor.instagram}
                instagramUrl={vendor.instagramUrl}
                categories={vendor.categories}
              />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default VendorDirectory;
