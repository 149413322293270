import "../../App.scss";
import EventCard from "../EventCard/EventCard";
import "./upcoming-markets.scss";
import marketList from "./marketList";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const UpcomingMarkets = () => {
  const currentDate = new Date().getTime();

  console.log(currentDate);
  return (
    <div className="container">
      <div className="page-header" style={{ textAlign: "center" }}>
        <h1>Upcoming Markets</h1>
        <h3>
          Find markets to shop or to vend or{" "}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSe9u1Yl-Cx3JyELsOXuH4s49rWll5qIOUpuQ17IWF_szWnt3w/viewform"
            target="_blank"
          >
            submit a market
          </a>{" "}
        </h3>
      </div>
      <Row>
        {marketList.map((market) => {
          const convertedTime = new Date(market.startTime).getTime();
          if (convertedTime > currentDate) {
            return (
              <Col lg={4} md={6} sm={6}>
                <EventCard
                  title={market.title}
                  city={market.city}
                  state={market.state}
                  address={market.address}
                  startTime={market.startTime}
                  endTime={market.endTime}
                  description={market.description}
                  organizer={market.organizer}
                  organizerUrl={market.organizerUrl}
                  organizerImg={market.organizerImg}
                  applicationUrl={market.applicationUrl}
                  eventImg={market.eventImg}
                  applicationOpen={market.applicationOpen}
                  eventUrl={market.eventUrl}
                />{" "}
              </Col>
            );
          }
        })}
      </Row>
    </div>
  );
};

export default UpcomingMarkets;
