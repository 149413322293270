const marketList = [
  {
    city: "Portland",
    state: "OR",
    title: "Portland Bazaar",
    address: "2355 NW Vaughan St ",
    startTime: "2024-04-13T06:00:00.000Z",
    endTime: "2024-04-13T23:00:00.000Z",
    applicationOpen: true,

    description:
      "The PORTLAND BAZAAR is the city’s finest holiday gift showcase and sale. Started in 2011, our mission is to create an exciting event to highlight the skilled artists, makers and small businesses of Portland, Oregon.",
    organizer: "Portland Bazaar",
    organizerUrl: "https://www.portlandbazaar.com/",
    organizerImg: "",
    eventImg: "../../images/portland-bazaar.png",
    applicationUrl: "https://www.portlandbazaar.com/vendor-application",
    eventUrl: "https://www.portlandbazaar.com/",
  },
  {
    city: "Portland",
    state: "OR",
    title: "Portland Night Market: Spring Edition",
    address: "100 SE Alder Street",
    startTime: "2024-04-26T11:00:00.000Z",
    endTime: "2024-04-26T06:00:00.000Z",
    applicationOpen: true,
    description:
      "175+ of Portland's best vendors celebrating local food, culture, music, drinks and retail.",
    organizer: "Portland Night Market",
    organizerUrl: "https://www.pdxnm.com/",
    eventImg: "../../images/portland-night-market.jpg",
    eventUrl: "https://www.pdxnm.com/faq",
    organizerImg: "",
    applicationUrl:
      "https://docs.google.com/forms/d/e/1FAIpQLSed-II6nZ2h__rxgWsCRk2Or0O_O74o-pJrvktETohNV0ZGoA/viewform?fbzx=-8169580332744158206",
  },
  {
    city: "Portland",
    state: "OR",
    title: "Crafty Wonderland Spring Art + Craft Market",
    address: "777 NE Martin Luther King Jr. Blvd",
    startTime: "2024-05-03T19:00:00.000Z",
    endTime: "2024-05-04T00:00:00.000Z",
    applicationOpen: false,

    description:
      "Shop from the best and brightest artists and makers around! Vendor applications open Feb 1st.",
    organizer: "Crafty Wonderland",
    organizerUrl: "https://craftywonderland.com/",
    organizerImg: "../../images/spectrum-art-market-logo.jpeg",
    eventUrl: "https://craftywonderland.com/pages/faq",
    eventImg: "../../images/crafty-wonderland-spring-event.jpeg",
  },
  {
    city: "Portland",
    state: "OR",
    title: "My People's Market 14",
    address: "831 SE Salmon St",
    startTime: "2024-06-08T19:00:00.000Z",
    endTime: "2024-06-09T00:00:00.000Z",
    applicationOpen: true,
    applicationUrl: "https://form.jotform.com/240575266067158",
    description:
      "Shop from the best and brightest artists and makers around! Vendor applications open Feb 1st.",
    organizer: "My People's Market",
    organizerUrl: "https://www.mypeoplesmarket.com/",
    organizerImg: "../../images/spectrum-art-market-logo.jpeg",
    eventUrl: "https://www.mypeoplesmarket.com/become-a-vendor-mpm",
    eventImg: "../../images/mpm-14-event.png",
  },
  {
    city: "Beaverton",
    state: "OR",
    title: "Legendary Makers Market II",
    address: "12600 SW Crescent St",
    startTime: "2024-07-26T22:00:00.000Z",
    endTime: "2024-07-28T03:00:00.000Z",
    applicationOpen: true,

    description:
      "Shop from the best and brightest artists and makers around! Vendor applications open Feb 1st.",
    organizer: "Asian American Town",
    organizerUrl: "https://www.facebook.com/asianamericantown",
    organizerImg: "../../images/spectrum-art-market-logo.jpeg",
    eventImg: "../../images/legendary-2.jpeg",
    applicationUrl:
      "https://docs.google.com/forms/d/e/1FAIpQLSfVtp0RPuIP3g0fRALJSGNbuiBd8LXTG9Zgi2dFzfJ6_WFRig/viewform?fbclid=IwAR2Gxd2zP-QCYMFfxbBtRirNGQZ3SzS0PoLzu3ZdTVtwYCcmLH30NZTMcwg",
    eventUrl:
      "https://www.facebook.com/events/s/legendary-makers-market-ii/765738602039364",
  },
];

export default marketList;
