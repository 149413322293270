import NavBar from "./components/Navigation/NavBar";
import HomePage from "./components/HomePage/HomePage";
import VendorDirectory from "./components/VendorDirectory/VendorDirectory";
import OrganizerDirectory from "./components/OrganizerList/OrganizerDirectory";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar></NavBar>
        {/* <OrganizerList /> */}
        <div className="app-container">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/vendor-directory" element={<VendorDirectory />} />
            <Route
              path="/organizer-directory"
              element={<OrganizerDirectory />}
            />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
