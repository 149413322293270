import "./vendor-directory.scss";

const VendorCard = ({
  name,
  bio,
  instagram,
  instagramUrl,
  logo,
  categories,
}) => {
  return (
    <div style={{ padding: ".5rem" }}>
      <div className="vendor-card">
        <div className="event-card-header">
          <div style={{ display: "flex" }}>
            {logo && (
              <div>
                <img className="vendor-logo" src={logo}></img>
              </div>
            )}
            <div>
              <h3 className="vendor-card-title">{name}</h3>
              <a href={instagramUrl} target="_blank">
                <p className="instagram-handle">{instagram}</p>
              </a>
            </div>
          </div>
          {categories?.map((category) => {
            return <div className="category-tag">{category}</div>;
          })}
          <p>{bio}</p>
        </div>
      </div>
    </div>
  );
};

export default VendorCard;
