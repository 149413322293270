import "../../App.scss";
import UpcomingMarkets from "../Upcoming Markets/UpcomingMarkets";
import VendorDirectory from "../VendorDirectory/VendorDirectory";

const HomePage = () => {
  return (
    <div>
      <UpcomingMarkets />
    </div>
  );
};

export default HomePage;
